<template>
  <!-- 实名认证 -->
  <div class="" style="">
    <div class="Ptit">
      <strong class="tit">实名认证</strong>
    </div>
    <div>
      <div v-if="state === 0" class="usHomeFormBox">
        <el-form
          ref="ruleFormRef"
          :model="ruleForm"
          :rules="rules"
          label-width="21vw"
          class="usHome-ruleForm"
          :size="formSize"
        >
          <el-form-item label="真实姓名" prop="name">
            <el-input v-model="ruleForm.name" />
          </el-form-item>
          <el-form-item label="身份证号" prop="userId">
            <el-input v-model="ruleForm.userId" />
          </el-form-item>
          <el-form-item label="身份证（正面）" prop="userIdImgA">
            <div v-if="ruleForm.userIdImgA === ''">
              <el-upload
                class="upload-demo"
                method="post"
                action=""
                :before-upload="uploadsA"
                multiple
                :limit="1"
                :file-list="fileList"
                style="display:flex;"
              >
                <el-button>上传文件</el-button>
                <template #tip>
                  <div class="el-upload__tip" style="margin-left:12px;">
                    jpg/png 格式，文件大小不超过 500KB.
                  </div>
                </template>
              </el-upload>
            </div>
            <div v-else>
              <img
                style="max-width:480px;max-height:360px;"
                :src="userIdImgA"
              />
              <span @click="ruleForm.userIdImgA = ''">删除</span>
            </div>
          </el-form-item>
          <el-form-item label="身份证（正面）" prop="userIdImgB">
            <div v-if="ruleForm.userIdImgB === ''" :title="ruleForm.userIdImgB">
              <el-upload
                class="upload-demo"
                method="post"
                action=""
                :before-upload="uploadsB"
                multiple
                :limit="1"
                :file-list="fileList"
                style="display:flex;"
              >
                <el-button>上传文件</el-button>
                <template #tip>
                  <div class="el-upload__tip" style="margin-left:12px;">
                    jpg/png 格式，文件大小不超过 500KB.
                  </div>
                </template>
              </el-upload>
            </div>
            <div :title="userIdImgB" v-else>
              <img
                style="max-width:480px;max-height:360px;"
                :src="userIdImgB"
              />
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm(ruleFormRef)"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div v-else-if="state === 1" class="">
        <div>
          <el-result
            icon="success"
            title="认证资料已提交"
            sub-title="请耐心等待管理人员审核"
          >
          </el-result>
        </div>
      </div>
      <div v-else-if="state === 2" class="">
        <div>
          <el-result
            icon="warning"
            title="认证资料审核不通过"
            sub-title="请重新上传审核资料"
          >
            <template #extra>
              <el-button type="primary" @click="chongxin">
                重新上传
              </el-button>
            </template>
          </el-result>
        </div>
      </div>
      <div v-else class="">
        <div class="showCerti">
          <div class="Ptes">
            <p>
              <label class="FsH6">真实姓名：</label>
              <span class="FsH2">{{ ruleForm.name }}</span>
            </p>
            <p>
              <label class="FsH6">身份证号：</label>
              <span class="FsH2">{{ ruleForm.userId }}</span>
            </p>
          </div>
          <div class="usIdImg">
            <el-image style="max-width:360px;" :src="userIdImgA" />
            <el-image style="max-width:360px;" :src="userIdImgB" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { uploadImg, postCertification, getCertificationShow } from '@/api/user'
// import { ElMessage, ElMessageBox } from 'element-plus'
// import { UploadProps, UploadUserFile } from 'element-plus'
// import { useRoute, useRouter } from 'vue-router'
import { sign, getItem, setItem, toUsId } from '@/utils/storage'
const state = ref(0)
state.value = getItem('userinfo').certification
const formSize = ref('default')
const ruleFormRef = ref()
const ruleForm = ref({
  ID: 0,
  name: '',
  userId: '',
  userIdImgA: '',
  userIdImgB: ''
})
ruleForm.value.ID = toUsId(getItem('token'))
// 重新上传
const chongxin = () => {
  state.value = 0
  // console.log('重新上传点击=', ruleForm.value)
}
const rules = {
  name: [
    { required: true, message: '不能为空', trigger: 'blur' },
    { min: 2, max: 40, message: '2-40个字符', trigger: 'blur' }
  ],
  userId: [
    { required: true, message: '不能为空', trigger: 'blur' },
    { min: 18, max: 18, message: '身份证为18个字符', trigger: 'blur' }
  ],
  userIdImgA: [{ required: true, message: '请上传图片', trigger: 'blur' }],
  userIdImgB: [{ required: true, message: '请上传图片', trigger: 'blur' }]
}
// 提交
const submitForm = async formEl => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      postCertificationFun(ruleForm.value)
    } else {
      console.log('提交失败', fields)
    }
  })
}
const postCertificationFun = async data => {
  var revv = await postCertification(sign(data))
  if (revv === 'ok') {
    state.value = 1
    var usinfo = getItem('userinfo')
    usinfo.certification = 1
    setItem('userinfo', usinfo)
  }
}

// 上传
// const uploadURL = ref(window.g.baseURL + 'api/uploadImg') // http://127.0.0.1:8000/admin/upload/img
// const uploadURL = ref(window.g.baseURL + 'sys/uploadImg')
console.log(uploadImg, ruleForm.value, sign({ aa: 'bb' }))
const fileList = ref([])

// 上传文件
const userIdImgA = ref('')
const uploadsA = async (file, uploadFiles) => {
  var formData = new FormData()
  formData.append('file', file)
  formData.append('tg', 'ok')
  var revv = await uploadImg(formData)
  ruleForm.value.userIdImgA = revv
  userIdImgA.value = window.g.baseURL + revv
  console.log('ruleForm.value--', ruleForm.value)
}
const userIdImgB = ref('')
const uploadsB = async (file, uploadFiles) => {
  var formData = new FormData()
  formData.append('file', file)
  formData.append('tg', 'ok')
  var revv = await uploadImg(formData)
  ruleForm.value.userIdImgB = revv
  userIdImgB.value = window.g.baseURL + revv
  console.log('ruleForm.value--', ruleForm.value)
}

// 获取认证内容
// const usRz = ref({})
const getCertificationShowFun = async () => {
  ruleForm.value = await getCertificationShow(sign({ ID: ruleForm.value.ID }))
  ruleForm.value.name = getItem('userinfo').name

  userIdImgB.value = window.g.baseURL + ruleForm.value.userIdImgB
  userIdImgA.value = window.g.baseURL + ruleForm.value.userIdImgA
  ruleForm.value.ID = toUsId(getItem('token'))
  // console.log('获取烽据=', ruleForm.value)
}
if (state.value > 1) {
  getCertificationShowFun()
}
</script>

<style lang="scss" scoped>
.showCerti {
  margin-left: 12vw;
  margin-right: 10vw;
  margin-top: 3rem;
}
.usIdImg {
  margin-top: 2rem;
}
</style>
